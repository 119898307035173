<template>

    <router-link  :to="{ name: 'TheEssentialMobile', params: { id: data.id } }">
  
        <div class="h-32 flex flex-row justify-start items-center mt-2 bg-filters mb-2 rounded-lg px-2 shadow-md">

            <div v-if="data.image" class="h-24 w-24 rounded-lg mr-2 bg-center"
                    :style="'background-image: url(' + imageRoute + data.image + '); ' + 'background-repeat: no-repeat; background-size: cover'">
            </div>

            <div class="h-full flex-1 flex flex-col">

                <div class="h-4 flex flex-row justify-end items-center">
                    <span class="text-3xs text-warning">{{ data.date | moment('DD / MM / YYY') }}</span>
                </div>

                <div class="h-auto max-h-8 mb-1">
                    <p class="text-xs font-semibold leading-none truncate-2">{{ data.title }}</p>
                </div>

                <div class="flex-1 min-h-0 overflow-hidden mb-1">
                    <p class="text-3xs text-font-gray" v-html="data.body"></p>
                </div>

                <div class="h-4 flex flex-row justify-end items-center">

                    <i v-if="data.link !== ''" class="mdi mdi-open-in-new text-font-gray cursor-pointer text-xs mr-2" @click="goLink(data.link)"></i>
                    <i v-if="data.file !== ''" class="mdi text-xs cursor-pointer" :class="extension(data.file)" @click="openFile(data.file)"></i>

                    <span class="text-white text-xxs ml-2 mr-1">{{ data.likes.length }}</span>
                    <i class="mdi mdi-thumb-up-outline text-font-gray text-xxs" :class="melike(data.likes)"></i>

                </div>

            </div>

        </div>

    </router-link>

</template>

<script>
export default {
    props:['data'],
    data(){
        return{

        }
    },
    methods:{
        extension(file){
            let result = ''
            result += this.$iconByExtension(file)
            return result
        },
        melike(essential){ 
            return this.$meLike(essential) 
        },
        dolike(id) {
            this.axios.post(process.env.VUE_APP_BACKEY_CMS_URL + 'like?resource=Essential&id='+id)
                .then(response => {
                    this.load();
                })
        },
        goLink(link){
            this.$goLink(link) 
        },
        openFile(file, type){
            this.$openFile(file, type)
        }
    },
    computed:{
        imageRoute() { 
            return this.$imageRoute 
        }
    }
}
</script>

<style>

</style>