<template>
    
    <div class="h-auto w-full flex flex-col">

        <div v-if="essentials.length > 0" class="h-auto p-2">

            <div v-if="!selectedEssential" class="h-auto w-full rounded-lg module p-2 flex flex-col">

                <div class="h-auto py-1">
                    <span class="text-warning text-xs font-semibold">The Essential</span>
                </div>

                <div class="h-6 rounded-lg bg-body my-2">
                    <input type="text" placeholder="Search" v-model="searchInput" class="w-full h-6
                        rounded bg-body flex flex-row justify-start text-xs items-center px-2">
                </div>

                <div v-if="essentials.length > 0 && !searchInput" class="h-72 bg-filters rounded-lg p-2 flex flex-col shadow-md">

                    <div class="h-auto flex flex-row justify-start items-center">

                        <span class="text-font-gray font-semibold text-xxs mr-auto">{{ essentials[0].date | moment('DD / MM / YYYY') }}</span>

                    </div>

                    <div class="h-auto max-h-16 mt-1">
                        <span class="text-sm font-semibold truncate-3">{{ essentials[0].title }}</span>
                    </div>

                    <div v-if="essentials[0].image" class="h-24 bg-white mt-1 rounded-lg" :style="'background-image: url(' + imageRoute + essentials[0].image + '); ' + 'background-repeat: no-repeat; background-size: cover'">                    </div>

                    <div class="flex-1 min-h-0 mt-1 overflow-auto">
                        <span class="text-font-gray text-xs" v-html="essentials[0].body"></span>
                    </div>

                    <div class="h-auto flex flex-row justify-end items-center px-2">
                        <span class="text-white text-xxs mr-1">{{ essentials[0].likes.length }}</span>
                        <i class="mdi mdi-thumb-up-outline text-font-gray text-xxs" @click="dolike(essentials[0].id)" :class="melike(essentials[0].likes)"></i>
                    </div>

                </div>

                <div v-if="essentials.length > 1 && !searchInput" class="px-1 h-auto py-1 mt-4 flex flex-row justify-start items-center">
                    <span class="text-font-gray font-semibold text-sm">Latest</span>
                </div>

                <div v-if="essentials.length > 1" class="h-auto rounded-lg flex flex-col">

                    <essential-post v-for="(el, index) in search(essentials)" :key="index" :data="el"/>

                </div>

            </div>

            <div v-else class="h-96 bg-filters rounded-lg p-2 flex flex-col shadow-md">

                <div class="h-6 flex flex-row justify-start items-center">

                    <span class="text-font-gray font-semibold text-xxs mr-auto">{{ selectedEssential.date | moment('DD / MM / YYYY') }}</span>

                    <router-link :to="{ name: 'TheEssentialMobile'}" class="h-6 w-6 rounded-full cursor-pointer flex flex-row justify-center items-center">
                        <i class="mdi mdi-close text-white font-semibold cursor-pointer"></i>
                    </router-link>

                </div>

                <div class="h-auto max-h-16 mt-1">
                    <span class="text-sm font-semibold truncate-3">{{ selectedEssential.title }}</span>
                </div>

                <div v-if="selectedEssential.image" class="h-24 mt-2 rounded-lg" :style="'background-image: url(' + imageRoute + selectedEssential.image + '); ' + 'background-repeat: no-repeat; background-size: cover'">                    </div>

                <div class="flex-1 min-h-0 mt-2 overflow-auto">
                    <span class="text-font-gray text-xs" v-html="selectedEssential.body"></span>
                </div>

                <div class="h-auto flex flex-row justify-end items-center px-2">
                    <span class="text-white text-xxs mr-1">{{ selectedEssential.likes.length }}</span>
                    <i class="mdi mdi-thumb-up-outline text-font-gray text-xxs" @click="dolike(selectedEssential.id)" :class="melike(selectedEssential.likes)"></i>
                </div>

            </div>

        </div>
        
        <div v-else>
            <loader :loading="loading" />
        </div>

    </div>

</template>

<script>
import essentialPost from '../../components/Mobile/personalDashboard/EssentialPost.vue';
import {state} from '@/store';

export default {
    components:{
        essentialPost
    },
    data() {
        return {
            essentials: [],
            loading: false,
            searchInput: '',
        }
    },
    methods: {
        extension(file){
            let result = ''
            result += this.$iconByExtension(file)
            return result
        },
        openFile(file, type){
            this.$openFile(file, type) 
        },
        goLink(link){
            this.$goLink(link) 
        },
        search(data){
            return this.$search(data, this.searchInput) 
        },
        async load() {
            try {
                this.loading = true
                const { data } = await this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'list?resource=Essential&sorts[date]=DESC&filters[active]=1', {
                    params: {
                        ...state.globalParams, 
                        role_type_id: state.user.role_default.role_type_id,
                        gui_role_specific_id: state.user.role_default.gui_role_specific_id
                    }
                })
                
                this.essentials = data;

                this.selectEssential(this.essentialId);
                
            } catch (error) {
            } finally {
                this.loading = false;
            }
            
        },
        dolike(id) {
            this.axios.post(process.env.VUE_APP_BACKEY_CMS_URL + 'like?resource=Essential&id='+id)
                .then(response => {
                    this.load();
                })
        },
        melike(essential){ 
            return this.$meLike(essential) 
        },
        // selectEssential(id) {
        //     if (id) {
        //         this.selected = this.essentials.find(item => String(item.id) === String(id));
        //     } else {
        //         this.selected = null;
        //     }
        // },
    },
    computed: {
        imageRoute() { 
            return this.$imageRoute 
        },
        essentialId() {
            return this.$route.params.id;
        },
        selected(){
            return this.$route.params.id
        },
        selectedEssential(){
            if(this.essentials != [] && this.selected){
                return this.essentials.find(element => element.id == this.selected)
            }
        }
    },
    watch: {
        essentialId(id) {
            this.selectEssential(id);
        }
    },
    mounted() {
        this.load();
    }

}
</script>